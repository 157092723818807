import React, { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { ColumnType } from "src/types/enhancedTable";
import userService from "src/services/userService";
import { IAdministratorsData } from "src/ts/interfaces";
import { spacing, SpacingProps } from "@mui/system";
import { Chip as MuiChip, IconButton, Stack } from "@mui/material";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { Key as KeyIcon } from "@mui/icons-material";
import PermissionsPopup from "./components/PermissionsPopup";
import useLoading from "src/hooks/useLoading";
import { formatPhoneNumber } from "src/utils/format";
import { ToolTip } from "src/components/others/ToolTip";
import { GridActionButton } from "src/components/gridControls";
import { EmailIcon } from "src/components/icons";
import useAsyncMutation from "src/hooks/useAsyncMutation";
import { Permission as PermissionTypes } from "src/ts/enums";
import { useLog, usePermissions } from "src/hooks";
import { ROLES } from "src/constants";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);

interface companyTableProps {
  refresh: boolean;
  statusCode: string;
  filter: string;
}

function AdministratorsData(props: companyTableProps) {
  const { log } = useLog();
  let navigate = useNavigate();
  const [dataSet, setDataSet] = useState<IAdministratorsData[]>([]);
  const [refresh, setRefresh] = useState(props.refresh);
  const [Permissions, setPermissions] = useState<IAdministratorsData>();
  const [disablePopup, setDisablePopup] = useState(false);

  const { execute: sendEmail } = useAsyncMutation(userService.sendEmail, {
    successfulMessage: "Account confirmation email was successfully sent",
  });

  const sendEmailHandler = (userId: number) => {
    sendEmail(userId, false);
  };

  const { fullAccess, readOnly } = usePermissions(
    PermissionTypes.Administrators
  );

  const { fullAccess: fullAccessUnion, readOnly: readOnlyUnion } =
    usePermissions(PermissionTypes.Union_Administrator);

  const { fullAccess: fullAccessSysAdmin, readOnly: readOnlySysAdmin } =
    usePermissions(PermissionTypes.System_Administrators);

  const getColumns = (
    navigate: NavigateFunction,
    openPermissionPopup: (val: boolean) => void,
    setPermissions: (val: IAdministratorsData) => void
  ): ColumnType[] => [
    {
      id: "id",
      label: "Admin ID",
      type: "string",
      sort: true,
    },
    {
      id: "getFullName",
      label: "Name",
      type: "string",
      sort: true,
    },
    {
      id: "phone",
      label: "Phone",
      type: "custom",
      sort: true,
      callback: (row: IAdministratorsData) => formatPhoneNumber(row.phone),
    },
    {
      id: "email",
      label: "Email",
      type: "custom",
      sort: true,
      callback: (row: IAdministratorsData) => {
        var input = row.email;
        var fields = input.split("@");
        return (
          <>
            <Stack>{fields[0]}@</Stack>
            <Stack>{fields[1]}</Stack>
          </>
        );
      },
    },
    {
      id: "userCountryAccess",
      label: "Country",
      type: "string",
      sort: true,
    },
    {
      id: "isEmailConfirmed",
      label: "Email Confirmation Status",
      type: "custom",
      sort: true,
      callback: (row: IAdministratorsData) => {
        return (
          <>
            {row.isEmailConfirmed ? (
              <Chip label="Yes" color="success" m={1} size="small" />
            ) : (
              <Chip label="No" color="error" m={1} size="small" />
            )}
          </>
        );
      },
    },
    {
      id: "administratorTypeDesc",
      label: "Administrator Type",
      type: "string",
      sort: true,
    },
    {
      id: "x",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: IAdministratorsData) => (
        <div>
          <Stack direction="row">
            <GridActionButton
              type={
                (
                  row.administratorType === 1 || row.administratorType === 3
                    ? fullAccess
                    : row.administratorType === 4
                    ? fullAccessSysAdmin
                    : fullAccessUnion
                )
                  ? "edit"
                  : "view"
              }
              onClick={() => {
                navigate(`/app/administrators/${row.id}`);
              }}
              tooltip={
                (
                  row.administratorType === 1 || row.administratorType === 3
                    ? fullAccess
                    : row.administratorType === 4
                    ? fullAccessSysAdmin
                    : fullAccessUnion
                )
                  ? "Edit"
                  : "View"
              }
            />
            {((row.administratorType === 1 || row.administratorType === 3
              ? fullAccess
              : row.administratorType === 4
              ? fullAccessSysAdmin
              : fullAccessUnion) ||
              (row.administratorType === 1 || row.administratorType === 3
                ? readOnly
                : row.administratorType === 4
                ? readOnlySysAdmin
                : readOnlyUnion)) && (
              <ToolTip title={"Permissions"}>
                <IconButton
                  aria-label="VpnKey"
                  size="large"
                  onClick={() => {
                    openPermissionPopup(true);
                    setPermissions(row);
                  }}
                >
                  <KeyIcon />
                </IconButton>
              </ToolTip>
            )}

            {(row.administratorType === 1 || row.administratorType === 3
              ? fullAccess
              : row.administratorType === 4
              ? fullAccessSysAdmin
              : fullAccessUnion) && (
              <ToolTip title="Resend Invitation">
                <IconButton
                  aria-label="delete"
                  size="small"
                  disabled={row.isEmailConfirmed}
                  onClick={() => {
                    if (row.id !== null) sendEmailHandler(row.id);
                  }}
                >
                  <EmailIcon />
                </IconButton>
              </ToolTip>
            )}
          </Stack>
        </div>
      ),
    },
  ];
  let columns = getColumns(navigate, setDisablePopup, setPermissions);
  const { isLoading, startRequest, endRequest } = useLoading();

  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("usersTable");
        const request = await userService.getAll();
        if (props.statusCode === "Active") {
          const activeFilter = request.data.filter((option) => {
            return option.statusId === 1;
          });
          setDataSet(activeFilter);
        } else {
          const inactiveFilter = request.data.filter((option) => {
            return option.statusId === 2;
          });
          setDataSet(inactiveFilter);
        }

        endRequest("usersTable");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDataSet, refresh, setPermissions]);

  const handleSave = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      <LocalEnhancedTable<IAdministratorsData>
        columns={columns}
        data={dataSet ?? []}
        defaultSortColumn="getFullName"
        orderColumn="desc"
        defaultRowPerPage={10}
        query={props.filter}
        filterCustom={[
          "getFullName",
          "phone",
          "email",
          "userCountryAccess",
          "administratorTypeDesc",
        ]}
        showSkeleton={isLoading}
      />
      <PermissionsPopup
        currentValues={Permissions}
        disablePopup={disablePopup}
        setDisablePopup={setDisablePopup}
        onSave={handleSave}
        onClick={() => null}
        role={
          Permissions?.administratorType === 4
            ? "SysAdmin"
            : Permissions?.administratorType === 1
            ? "SuperAdministrator"
            : Permissions?.administratorType === 2
            ? "UnionAdministrator"
            : "Trainer"
        }
      />
    </>
  );
}

export default AdministratorsData;
