import {
  CardContent,
  Grid,
  Stack,
  Divider as MuiDivider,
  Card as MuiCard,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
} from "@mui/material";

import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FormCheckBox,
  FormCheckSwitch,
  FormNumeric2,
  FormRadioGroup,
  FormSelect,
  FormText,
  FormLabel,
  FormTextArea,
  FormButton,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IFormulaSystemService,
  IKeyValue,
  IProjectReportInfectiousComplianceControl,
  IPsychrometricArgs,
} from "src/ts/interfaces";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useEffectOnce,
  useLog,
  usePsychrometric,
  useSystemOfMeasurement,
} from "src/hooks";
import {
  IDimensions,
  IVelgridMatrix,
} from "src/ts/interfaces/study/velgridMatrix";
import velgridMatrixService from "src/services/study/velgridMatrixService";
import { DuctType } from "src/constants";
import { CatalogService } from "src/services";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import VelgridGrid from "./components/VelgridGrid";
import { Validator } from "src/ts/types";
import useFormulas from "src/hooks/useFormulas";
import projectService from "src/services/project/projectService";
import elevationCorrectedService from "src/services/elevationCorrectedService";
import getProjectService from "src/services/project/getProjectService";
import SteadyStateDialog from "../components/SteadyState";
import { FooterButtons } from "../components/FooterButtons";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import {
  DashboardCustomizeOutlined,
  HvacOutlined,
  StraightenOutlined,
  ViewModuleOutlined,
} from "@mui/icons-material";
import CompleteStudyPopup from "../components/CompleteStudyPopup";
import getProjectSingleService from "src/services/getProjectSingleService";

const initialValues: IVelgridMatrix = {
  id: 0,
  projectId: 0,
  projectName: "",
  reportTypeId: 0,
  systemField: "",
  description: "",
  supply: false,
  return: false,
  exhaust: false,
  numberOfDucts: 0,
  systemTotal: false,
  outletTotal: false,
  conditionId: 0,
  temperatureCompensated: false,
  barometricalyCompensated: false,
  centerlineStaticPressureId: 0,
  x: 0,
  y: 0,
  relativeHumidityBool: false,
  wetBulbFBool: false,
  dewPointBool: false,
  ductSize: 0,
  ductSizeBase: 0,
  ductSizeHeight: 0,
  comments: "",
  nocVisual: false,
  nocActual: false,
  ductTypeId: DuctType.Rectangular,
  outsideAir: false,
  isActualLocalBarometricPressure: false,
  step: 0,
  isComplete: false,
  createdDate: new Date(),
  createdBy: "",
  updatedDate: new Date(),
  updatedBy: "",
  userAuth: "",
  userName: "",
  totalHeat: false,
  customMatrix: false,
};

interface IMessagePsychrometric {
  text: string;
  color: "info" | "warning" | "success" | "error";
}

const typeOfTerminal = [
  {
    key: 1,
    labelCustom: (
      <>
        <Stack direction={"row"}>
          <ViewModuleOutlined />
          <Typography mt={0.2}>
            <b>Rectangular</b>
          </Typography>
        </Stack>
      </>
    ),
  },
  {
    key: 2,
    labelCustom: (
      <>
        <Stack direction={"row"} spacing={1}>
          <HvacOutlined />
          <Typography mt={0.2}>
            <b>Round</b>
          </Typography>
        </Stack>
      </>
    ),
  },
];

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const VelgridReport = () => {
  const { user } = useAuth();
  const { log } = useLog();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const { psychrometric } = usePsychrometric();
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const { formulaSystem } = useFormulas();
  const [formulas, setFormulas] = useState<IFormulaSystemService>();
  const [totalCFM, setTotalCFM] = useState(0);
  const [disableAfterSteadyStep, setDisableAfterSteadyStep] =
    useState<boolean>(false);
  const [conditionOfTest, setConditionOfTest] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [selectRadioButton] = useState(initialValues.ductTypeId);
  const [stepContinuos, setStepContinuos] = useState("step1");
  const [dataX, setDataX] = useState(0);
  const [dataY, setDataY] = useState(0);
  const [project, setProject] = useState<ProjectDTO | null>();
  const [velgridMatrix, setVelgridMatrix] = useState<IVelgridMatrix | null>();
  const [projectReport, setProjectReport] = useState<IProjectReport | null>();
  const [infectiousComplianceControl, setInfectiousComplianceControl] =
    useState<IProjectReportInfectiousComplianceControl | null>(null);
  const [parentStudy, setParentStudy] = useState<IProjectReport | null>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [messagePsychrometric, setMessagePsychrometric] =
    useState<IMessagePsychrometric>({ text: "", color: "info" });
  const [showSteady, setShowSteady] = useState(false);
  const [disableEditData, setDisableEditData] = useState(false);
  const [changeMatrixButton, setChangeMatrixButton] = useState(false);
  const [buttonAction, setButtonAction] = useState(false);
  const [dimensions, setDimensions] = useState<IDimensions>({
    width: 0,
    height: 0,
  });
  let navigate = useNavigate();

  const { execute: executeUpdate, isSubmitting: isSubmittingUpdate } =
    useAsyncMutation<number>(velgridMatrixService.update, {
      successfulMessage: "Report was updated successfully",
      onSuccess: (result) => {
        const valuePrevious = values;
        setValues({
          ...result,
          nocOaNumber:
            valuePrevious?.nocOaNumber !== null
              ? valuePrevious?.nocOaNumber
              : 0,
          nocMaNumber:
            valuePrevious?.nocMaNumber !== null
              ? valuePrevious?.nocMaNumber
              : 0,
          nocRaNumber:
            valuePrevious?.nocRaNumber !== null
              ? valuePrevious?.nocRaNumber
              : 0,
          nocExhaustNumber:
            valuePrevious?.nocExhaustNumber !== null
              ? valuePrevious?.nocExhaustNumber
              : 0,
        });
        if (stepContinuos === "step1") {
          setDataX(values?.x);
          setDataY(values?.y);
          setDimensions({
            width: values?.dimensionWidth ?? 0,
            height: values?.dimensionHeight ?? 0,
          });
        }

        validateTotalCFM(result?.totalCFM ?? 0);
      },
    });

  const { execute: executeRefresh, isLoading: isLoadingRefresh } =
    useAsyncQuery(velgridMatrixService.update, {
      onSuccess: async (result) => {
        setValues({
          ...result,
        });
        await validateTotalCFM(result?.totalCFM ?? 0);
      },
    });

  const { execute: executeRefreshPsyco } = useAsyncQuery(
    velgridMatrixService.update,
    {
      onSuccess: (result) => {
        setValues({
          ...result,
        });
        validateTotalCFM(result?.totalCFM ?? 0);
        calcPsycometrics(result?.totalCFM!, result);
      },
    }
  );

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (params.step === "step2") {
      temp.dryBulbF = new Validator(fieldValues, "dryBulbF")
        .isRequired("Airflow Temperature For Energy Calculation Is Required")
        .toString();
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IVelgridMatrix>(initialValues, false, validate);

  const { execute, isLoading } = useAsyncQuery<IVelgridMatrix>(
    velgridMatrixService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const formulas = await formulaSystem.setSystem(dataResult.projectId);
          setFormulas(formulas);
          if (!dataResult.ductTypeId)
            dataResult.ductTypeId = DuctType.Rectangular;
          if (
            !dataResult.relativeHumidityBool &&
            !dataResult.wetBulbFBool &&
            !dataResult.dewPointBool
          ) {
            dataResult.relativeHumidityBool = true;
          }
          if (dataResult.localBarometricPressure == null) {
            const zipCodeResponse = await projectService.getZipCode(
              dataResult.projectId
            );

            const elevationCorrectedResponse =
              await elevationCorrectedService.getById(
                Number(zipCodeResponse.data)
              );

            dataResult.localBarometricPressure = formulas.InHgToKPa(
              elevationCorrectedResponse.data.atmosphericPressure
            );
          }

          setDataX(dataResult.x === null ? 0 : dataResult.x);
          setDataY(dataResult.y === null ? 0 : dataResult.y);

          setDimensions({
            width:
              dataResult.dimensionWidth === null
                ? 0
                : dataResult.dimensionWidth,
            height:
              dataResult.dimensionHeight === null
                ? 0
                : dataResult.dimensionHeight,
          });
          setValues({
            ...dataResult,
            dimensionTotal:
              dataResult.dimensionTotal === null
                ? 0
                : dataResult.dimensionTotal,
          });
          validateTotalCFM(dataResult?.totalCFM ?? 0);
          const measurementSystemResponse =
            await getProjectService.getMeasurementSystem(
              dataResult.projectId ?? values.projectId
            );
          systemOfMeasurement.setSystem(measurementSystemResponse.data);
        };
        getData();
      },
    }
  );

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const [activeStep, setActiveStep] = useState<number>(
    params.step === "step1" ? 0 : 1
  );
  const [saveItems, setSaveItems] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      await execute(id);
      await executeReport(id, "VEMR");
    };

    if (id !== 0) getData();

    const getDropdown = async () => {
      var result = await CatalogService.getAllDropdown(
        "ConditionPitotTraverse"
      );
      setConditionOfTest(result);
    };
    getDropdown();
    setDisableAfterSteadyStep(
      projectService.disableAfterSteadyStep(user?.role)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    let dimensionTotal = formulas?.sqft(
      ((values?.dimensionWidth ?? 0) - (values?.insulationThickness ?? 0) * 2) *
        ((values?.dimensionHeight ?? 0) -
          (values?.insulationThickness ?? 0) * 2)
    );

    setValues({
      ...values,
      dimensionTotal: dimensionTotal,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.dimensionWidth,
    values?.dimensionHeight,
    values?.insulationThickness,
  ]);

  useEffect(() => {
    if (!values?.totalHeat) {
      setMessagePsychrometric({ text: "", color: "info" });
    }
  }, [values?.totalHeat]);

  useEffect(() => {
    let ductSizeBaseCalc =
      values?.ductSizeBase - (values?.insulationThickness ?? 0) * 2;

    let ductSize = formulas?.sqft(
      (ductSizeBaseCalc / 2) * (ductSizeBaseCalc / 2) * 3.14159265359
    );

    setValues({
      ...values,
      ductSize: ductSize ?? 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.ductSizeBase, values?.insulationThickness]);

  useEffect(() => {
    if (!disableStudy && dataReport)
      calcPsycometrics(values?.totalCFM!, values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCFM, dataReport]);

  useEffect(() => {
    setActiveStep(params.step?.toLowerCase() === "step1" ? 0 : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.step]);

  const isOther = useMemo(() => {
    const found = conditionOfTest.find((item) => item.value === "Other");
    if (found?.key === values?.conditionId) return true;
    else return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.conditionId]);

  const calcTotal = async (totalItems: number) => {
    if (values && values.id > 0) {
      values.totalVelocity = totalItems;
      values.avgVelocity = totalItems / (values.x * values.y);
      setValues({
        ...values,
      });
      await executeRefresh(values.id, values);
    }
  };

  function extractNumber(inputString: string) {
    const match = inputString.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
  }

  const saveOnBlurHandler = async (e: any, ductType?: number) => {
    if (e.target.name === "ductTypeId") e.target.value = ductType;
    const newValues = {
      ...values,
      [e.target.name]:
        typeof e.target.value === "string"
          ? extractNumber(e.target.value)
          : e.target.value,
    };

    await executeRefresh(newValues.id, newValues);
  };

  const validateTotalCFM = (totalCFM: number) => {
    if (totalCFM.toFixed(6) !== values?.totalCFM?.toFixed(6))
      setTotalCFM(totalCFM);
  };

  const onBlurCalcPsycometrics = (e: any) => {
    const newValues = { ...values, [e.target.name]: e.target.value };
    calcPsycometrics(newValues?.totalCFM!, newValues);
  };

  const calcPsycometrics = async (total: number, newValues: any) => {
    let humidityMeasuredAs = "";
    let humidityValue = 0;
    if (newValues?.relativeHumidityBool) {
      humidityMeasuredAs = "RH";
      humidityValue = newValues?.relativeHumidity ?? 0;
    }
    if (newValues?.wetBulbFBool) {
      humidityMeasuredAs = "WB";
      humidityValue = newValues?.wetBulbF ?? 0;
    }
    if (newValues?.dewPointBool) {
      humidityMeasuredAs = "DP";
      humidityValue = newValues?.dewPoint ?? 0;
    }
    const psychometricsArgs: IPsychrometricArgs = {
      projectId: newValues.projectId,
      humidityMeasuredAs: humidityMeasuredAs,
      humidityValue: humidityValue,
      dryBulbF: newValues.dryBulbF!,
      totalCFM: total,
    };

    if (
      newValues?.totalHeat &&
      !isNaN(humidityValue) &&
      !isNaN(newValues?.dryBulbF ?? 0) &&
      !isNaN(newValues?.totalCFM ?? 0)
    ) {
      const psychrometricRes = await psychrometric.getByProject(
        psychometricsArgs
      );
      setMessagePsychrometric({ text: "", color: "info" });
      if (stepContinuos !== "step1") {
        if (
          psychrometricRes.psychrometric.message !== null &&
          psychrometricRes.psychrometric.enthalpy === 0
        )
          setMessagePsychrometric({
            text: psychrometricRes.psychrometric.message,
            color: "warning",
          });
        else if (psychrometricRes.psychrometric.message !== null) {
          setMessagePsychrometric({
            text: psychrometricRes.psychrometric.message,
            color: "info",
          });
        }
      }
      newValues.btuh = psychrometricRes.btuh!;
      newValues.sensibleHeat = psychrometricRes.sensibleHeat!;
      setValues({
        ...newValues,
      });
      if (newValues.id > 0) await executeRefresh(newValues.id, newValues);
    }
  };

  const handleChangeSpecificDefault = async (e: any) => {
    let isActualLocalBarometricPressure = e.target.value;
    const copyValues = { ...values };
    if (isActualLocalBarometricPressure) {
      const psychometricsArgs: IPsychrometricArgs = {
        projectId: values.projectId,
        humidityMeasuredAs: "RH",
        humidityValue: 50,
        dryBulbF:
          systemOfMeasurement.measurementSystem === "imperialSystem"
            ? 70
            : 21.11,
        totalCFM: 0,
      };
      const psychrometricRes = await psychrometric.getByProject(
        psychometricsArgs
      );
      setValues({
        ...values,
        useSiteSpecificDefault:
          psychrometricRes.psychrometric?.atmosphericPress,
        isActualLocalBarometricPressure: isActualLocalBarometricPressure,
      });
      copyValues.useSiteSpecificDefault =
        psychrometricRes.psychrometric?.atmosphericPress;
      copyValues.isActualLocalBarometricPressure =
        isActualLocalBarometricPressure;
    } else {
      setValues({
        ...values,
        isActualLocalBarometricPressure: isActualLocalBarometricPressure,
      });
      copyValues.isActualLocalBarometricPressure =
        isActualLocalBarometricPressure;
    }
    await executeRefreshPsyco(copyValues.id, copyValues);
  };

  const nextStepHandler = async () => {
    if (!disableStudy) {
      if (!validate()) return;
      await executeUpdate(values.id, values);
      setSaveItems(!saveItems);
    }
    if (params.step === "step1") navigate(`/studies/VelgridMatrix/step2/${id}`);
    else navigate(`/studies/VelgridMatrix/step3/${id}`);
    setActiveStep(1);
  };

  const handleCheckNoc = (e: any) => {
    values.nocActual = false;
    values.nocVisual = false;

    switch (e.target.name) {
      case "nocActual": {
        values.nocActual = true;
        break;
      }
      case "nocVisual": {
        values.nocVisual = true;
        break;
      }
    }
    setValues({ ...values });
  };
  const handleChangeCustomMatrix = async (e: any) => {
    const { name, value } = e.target;

    const newValues = { ...values, [name]: value };
    setValues(newValues);
    if (!value) {
      await executeRefresh(values.id, newValues);
    }
  };

  const isNOC = useMemo(() => {
    const found = conditionOfTest.find(
      (item) => item.value === "NOC (Normal Operating Condition)"
    );
    if (found?.key === values?.conditionId) return true;
    else return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.conditionId]);

  const handleSave = async () => {
    if (!validate()) return;
    await executeUpdate(values.id, values);

    setShowSteady(true);
  };

  const disableStudy = useMemo(() => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false ||
      (disableAfterSteadyStep && activeStep === 1)
    );
  }, [
    user?.userId,
    values?.isComplete,
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    disableAfterSteadyStep,
    activeStep,
  ]);

  const mode = disableStudy ? "read" : "read&Write";

  const disableStep1 = disableStudy || stepContinuos === "step1";

  const handleInputChangeBarometric = async (e: any) => {
    const copyValues = { ...values };
    setValues({
      ...values,
      barometricalyCompensated: e.target.value,
    });
    copyValues.barometricalyCompensated = e.target.value;
    await executeRefreshPsyco(copyValues.id, copyValues);
  };

  const handleInputChangeCompesation = (e: any) => {
    let temperatureCompensated = e.target.value;
    let dryBulbF = values?.dryBulbF;
    if (values.airflowTemperature === values.dryBulbF) {
      dryBulbF = formulas?.getAirflowTemperature();
    }
    let airflowTemperature = formulas?.getAirflowTemperature();
    setValues({
      ...values,
      temperatureCompensated: temperatureCompensated,
      dryBulbF: dryBulbF,
      airflowTemperature: airflowTemperature,
    });
  };

  const handleBackStep = async () => {
    if (!disableStudy) await handleSave();
    navigate(`/studies/VelgridMatrix/step1/${id}`);
    setActiveStep(0);
  };

  const handleChangeRadio = (e: any) => {
    const copyvalues = { ...values };
    copyvalues.relativeHumidityBool = false;
    copyvalues.dewPointBool = false;
    copyvalues.wetBulbFBool = false;
    copyvalues.relativeHumidity = undefined;
    copyvalues.wetBulbF = undefined;
    copyvalues.dewPoint = undefined;
    switch (e.target.name) {
      case "relativeHumidityBool": {
        copyvalues.relativeHumidityBool = true;
        break;
      }
      case "dewPointBool": {
        copyvalues.dewPointBool = true;
        break;
      }
      case "wetBulbFBool": {
        copyvalues.wetBulbFBool = true;
        break;
      }
    }
    calcPsycometrics(copyvalues?.totalCFM!, copyvalues);
  };

  const onChangeTypeDevice = (e: any) => {
    const { value } = e.target;
    setStepContinuos("step1");
    setButtonAction(false);
    setChangeMatrixButton(false);
    setDataX(0);
    setDataY(0);
    setDimensions({
      width: 0,
      height: 0,
    });
    setValues({
      ...values,
      ductTypeId: Number(value),
      dimensionTotal: 0,
      dimensionHeight: 0,
      dimensionWidth: 0,
      ductSizeBase: 0,
      x: 0,
      y: 0,
      totalVelocity: 0,
      avgVelocity: 0,
      trueCorectedAirVelocity: 0,
      conditionId: 0,
      totalCFM: 0,
      required: 0,
      akFactor: 0,
      percentajeOfDesign: 0,
      dryBulbF: 0,
      relativeHumidity: 0,
      wetBulbF: 0,
      dewPoint: 0,
      btuh: 0,
      sensibleHeat: 0,
      customMatrix: false,
    });
  };

  const handleBlurXY = (e: any) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: Number(value),
    });
  };

  useMemo(() => {
    const validateStep2 = async () => {
      let active = false;
      if (values?.ductTypeId === DuctType.Rectangular) {
        active = [
          values?.dimensionWidth,
          values?.dimensionHeight,
          values?.x,
          values?.y,
          values?.conditionId,
        ].every((value) => value !== 0 && value !== null);

        if (active) {
          setStepContinuos("step2");
          if (!disableStudy) await handleSave();
        }
      } else if (values?.ductTypeId === DuctType.Round) {
        active = [
          values?.ductSizeBase && values?.x && values?.y && values?.conditionId,
        ].every((value) => value !== 0 && value !== null);

        if (active) {
          setStepContinuos("step2");
          if (!disableStudy) await handleSave();
        }
      }
    };
    validateStep2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.dimensionWidth,
    values?.dimensionHeight,
    values?.ductSizeBase,
    values?.x,
    values?.y,
    values?.conditionId,
  ]);

  const getParentReportIsComplete = async (
    reportId: number,
    reportType: string
  ) => {
    try {
      const res = await projectReportService.report(reportId, reportType);
      setParentStudy(res.data);
    } catch (error) {}
  };

  useEffectOnce(() => {
    const getData = async () => {
      const velgridMatrixResponse = await velgridMatrixService.getById(id);
      setVelgridMatrix(velgridMatrixResponse.data);
      await getParentReportIsComplete(velgridMatrixResponse.data.id, "VEMR");
      await getProject(velgridMatrixResponse.data.projectId);
      await getProjectReport(
        velgridMatrixResponse.data.id,
        velgridMatrixResponse.data.reportTypeId,
        velgridMatrixResponse.data.projectId
      );
      await getInfectiousControlData(velgridMatrixResponse.data.id);
    };
    if (id > 0) getData();
  });

  const getInfectiousControlData = async (reportId: number) => {
    try {
      const res = await projectReportService.getInfectiousControl(reportId);
      setInfectiousComplianceControl(res.data);
    } catch (error: any) {
      //log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const getProjectReport = async (
    reportId: number,
    reportTypeId: number,
    projectId: number
  ) => {
    const projectReportResposne = await projectReportService.projectReport(
      reportId,
      reportTypeId,
      projectId
    );
    setProjectReport(projectReportResposne.data);
  };

  const getProject = async (projectId: number) => {
    const projectResponse = await getProjectSingleService.single(projectId);
    setProject(projectResponse.data);
  };

  const refreshCompletedStudy = async () => {
    try {
      const velgridMatrixResponse = await velgridMatrixService.getById(id);
      setVelgridMatrix(velgridMatrixResponse.data);
      const projectResponse = await getProjectSingleService.single(
        velgridMatrixResponse.data.projectId
      );
      setProject(projectResponse.data);
      await execute(id);
      await getProjectReport(
        velgridMatrixResponse?.data.id ?? 0,
        velgridMatrixResponse?.data.reportTypeId ?? 0,
        velgridMatrixResponse?.data.projectId ?? 0
      );
      setMessagePsychrometric({ text: "", color: "info" });
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const disableCompleteStudy =
    values?.dryBulbF === 0 || values?.dryBulbF === null;

  const showSteadyPopUp = useMemo(() => {
    return (
      showSteady && stepContinuos !== "step1" && values && values?.projectId > 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSteady, stepContinuos, values, values?.projectId]);

  const changeMatrix = () => {
    setButtonAction(true);
  };

  const showSkeleton = isLoadingRefresh || isLoading || isSubmittingUpdate;

  const showUpdateButton = useMemo(
    () =>
      !!(
        changeMatrixButton &&
        !disableStudy &&
        values?.x &&
        values?.y &&
        values?.x > 0 &&
        values?.y > 0
      ),
    [changeMatrixButton, disableStudy, values?.x, values?.y]
  );

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: "VEMR",
          system: values?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <h3>System Configuration</h3>
              <Grid container spacing={5}>
                <Grid item xs={12} md={3}>
                  <FormText
                    name="projectName"
                    label="Project Name"
                    value={values?.projectName}
                    onChange={handleInputChange}
                    error={errors.projectName}
                    showSkeleton={showSkeleton}
                    disabled={disableStudy}
                    mode={mode}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormText
                    name="systemField"
                    label="System"
                    value={values?.systemField}
                    onChange={handleInputChange}
                    error={errors.systemField}
                    showSkeleton={showSkeleton}
                    disabled={disableStudy}
                    mode={mode}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormText
                    name={"description"}
                    label={"Description"}
                    value={values?.description}
                    placeholder={""}
                    onChange={handleInputChange}
                    error={errors.description}
                    showSkeleton={showSkeleton}
                    disabled={disableStudy}
                    mode={mode}
                  ></FormText>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                  <h3>Type of Terminal Device being tested</h3>
                  <Grid container mt={4}>
                    <Grid item xs={12}>
                      <FormRadioGroup
                        name="ductTypeId"
                        value={values?.ductTypeId}
                        items={typeOfTerminal}
                        row
                        onChange={onChangeTypeDevice}
                        defaultValue={selectRadioButton}
                        showSkeleton={showSkeleton}
                        disabled={disableStudy}
                      />
                    </Grid>
                    {values?.ductTypeId === DuctType.Rectangular && (
                      <>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Stack mb={1}>
                              <FormLabel
                                text="Rectangular Dimension"
                                sx={{
                                  color: "rgba(0, 0, 0, 0.6)",
                                  marginBottom: "3px",
                                }}
                              />
                            </Stack>
                            <Grid container direction={"row"}>
                              <Grid item xs={disableStudy ? 1 : 5.8}>
                                <FormNumeric2
                                  name={"dimensionWidth"}
                                  label={"Width"}
                                  value={
                                    values?.ductTypeId === DuctType.Round
                                      ? 0
                                      : values?.dimensionWidth?.toString() ?? ""
                                  }
                                  onBlur={saveOnBlurHandler}
                                  fullWidth="150px"
                                  showSkeleton={
                                    stepContinuos === "step1"
                                      ? false
                                      : showSkeleton
                                  }
                                  decimalScale={3}
                                  thousandSeparator={true}
                                  suffix={` ${systemOfMeasurement.get("in")}`}
                                  size="small"
                                  disabled={disableStudy}
                                  mode={mode}
                                  requiredField={
                                    !Boolean(
                                      values?.dimensionWidth &&
                                        values?.dimensionWidth > 0
                                    )
                                  }
                                />
                              </Grid>
                              <Grid item xs={disableStudy ? 0.8 : 0.4}>
                                <Typography
                                  variant="subtitle2"
                                  mt={2}
                                  textAlign={disableStudy ? "left" : "center"}
                                >
                                  by
                                </Typography>
                              </Grid>
                              <Grid item xs={disableStudy ? 1 : 5.8}>
                                <FormNumeric2
                                  name={"dimensionHeight"}
                                  label={"Height"}
                                  value={
                                    values?.dimensionHeight?.toString() ?? ""
                                  }
                                  onBlur={saveOnBlurHandler}
                                  showSkeleton={
                                    stepContinuos === "step1"
                                      ? false
                                      : showSkeleton
                                  }
                                  decimalScale={3}
                                  thousandSeparator={true}
                                  suffix={` ${systemOfMeasurement.get("in")}`}
                                  size="small"
                                  disabled={disableStudy}
                                  mode={mode}
                                  requiredField={
                                    !Boolean(
                                      values?.dimensionHeight &&
                                        values?.dimensionHeight > 0
                                    )
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Grid item xs={12}>
                              <Stack mb={1}>
                                <FormLabel
                                  text="Number of readings"
                                  sx={{
                                    color: "rgba(0, 0, 0, 0.6)",
                                    marginBottom: "3px",
                                  }}
                                />
                              </Stack>
                              <Grid container direction={"row"}>
                                <Grid item xs={5} md={disableStudy ? 2 : 4}>
                                  <FormNumeric2
                                    name={"x"}
                                    label={"X axis ↔"}
                                    value={
                                      values?.ductTypeId === DuctType.Round
                                        ? 0
                                        : values?.x?.toString() ?? ""
                                    }
                                    onBlur={handleBlurXY}
                                    fullWidth="150px"
                                    showSkeleton={showSkeleton}
                                    decimalScale={3}
                                    thousandSeparator={true}
                                    disabled={
                                      !values?.customMatrix || disableStudy
                                    }
                                    error={errors.x}
                                    mode={mode}
                                    requiredField={
                                      !Boolean(values?.x && values?.x > 0)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={1} md={disableStudy ? 1 : 0.4}>
                                  {" "}
                                  <Typography
                                    variant="subtitle2"
                                    mt={2}
                                    textAlign={disableStudy ? "left" : "center"}
                                  >
                                    by
                                  </Typography>
                                </Grid>
                                <Grid item xs={5} md={disableStudy ? 2 : 4}>
                                  <FormNumeric2
                                    name={"y"}
                                    label={"Y axis ↕"}
                                    value={
                                      values?.ductTypeId === DuctType.Round
                                        ? 0
                                        : values?.y?.toString() ?? ""
                                    }
                                    onBlur={handleBlurXY}
                                    showSkeleton={showSkeleton}
                                    decimalScale={3}
                                    thousandSeparator={true}
                                    error={errors.y}
                                    disabled={
                                      !values?.customMatrix || disableStudy
                                    }
                                    mode={mode}
                                    requiredField={
                                      !Boolean(values?.y && values?.y > 0)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={3.5} ml={0.5}>
                                  <Stack
                                    direction={"row"}
                                    justifyContent={"center"}
                                  >
                                    <FormCheckBox
                                      name={"customMatrix"}
                                      label={"Custom Matrix"}
                                      value={values?.customMatrix}
                                      onChange={handleChangeCustomMatrix}
                                      showSkeleton={showSkeleton}
                                      disabled={disableStudy}
                                      mode={mode}
                                    ></FormCheckBox>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid
                            item
                            xs={7}
                            md={6}
                            spacing={2}
                            direction={"row"}
                          >
                            <Stack direction={"row"} spacing={2} mt={2}>
                              <StraightenOutlined />{" "}
                              <FormLabel
                                text="Dimension Total: "
                                sx={{ fontSize: "bold" }}
                              />{" "}
                              <FormLabel
                                text={` ${
                                  Number(
                                    values?.dimensionTotal?.toFixed(3)
                                  ).toLocaleString() ?? ""
                                }  ${systemOfMeasurement.get("sqft")}`}
                              />
                            </Stack>
                          </Grid>
                          <Grid item md={4} xs={1}></Grid>
                          <Grid item md={2} xs={4} direction={"row"}>
                            {values?.ductTypeId === DuctType.Rectangular && (
                              <Stack
                                direction={"row"}
                                mt={2}
                                justifyContent={"center"}
                                ml={0.5}
                              >
                                {showUpdateButton && (
                                  <FormButton
                                    startIcon={<DashboardCustomizeOutlined />}
                                    onClick={changeMatrix}
                                    text="Update Matrix"
                                    size="small"
                                    color="success"
                                    isSubmitting={showSkeleton}
                                  />
                                )}
                              </Stack>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {values?.ductTypeId === DuctType.Round && (
                      <>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={2.9}>
                            <Stack mb={1}>
                              <FormLabel
                                text="Round duct diameter ID"
                                sx={{
                                  color: "rgba(0, 0, 0, 0.6)",
                                  marginBottom: "3px",
                                }}
                              />
                            </Stack>
                            <Grid container direction={"row"}>
                              <Grid item xs={12}>
                                <FormNumeric2
                                  name={"ductSizeBase"}
                                  label={"Width"}
                                  value={
                                    values?.ductTypeId === DuctType.Rectangular
                                      ? 0
                                      : values?.ductSizeBase?.toString() ?? ""
                                  }
                                  onBlur={saveOnBlurHandler}
                                  fullWidth="150px"
                                  showSkeleton={showSkeleton}
                                  decimalScale={3}
                                  thousandSeparator={true}
                                  suffix={` ${systemOfMeasurement.get("in")}`}
                                  size="small"
                                  disabled={disableStudy}
                                  mode={mode}
                                  maxValue={999}
                                  requiredField={
                                    !Boolean(
                                      values?.ductSizeBase &&
                                        values?.ductSizeBase > 0
                                    )
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Stack direction={"row"} spacing={2} mt={2}>
                                <StraightenOutlined />{" "}
                                <FormLabel
                                  text="Dimension Total: "
                                  sx={{ fontSize: "bold" }}
                                />{" "}
                                <FormLabel
                                  text={` ${
                                    Number(
                                      values?.ductSize?.toFixed(3)
                                    ).toLocaleString() ?? ""
                                  }  ${systemOfMeasurement.get("sqft")}`}
                                />
                              </Stack>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} md={9.1}>
                            <Grid item xs={12}>
                              <Stack mb={1}>
                                <FormLabel
                                  text="Number of readings"
                                  sx={{
                                    color: "rgba(0, 0, 0, 0.6)",
                                    marginBottom: "3px",
                                  }}
                                />
                              </Stack>
                              <Grid container direction={"row"}>
                                <Grid item xs={5} md={disableStudy ? 2 : 2.5}>
                                  <FormNumeric2
                                    name={"x"}
                                    label={"X axis ↔"}
                                    value={
                                      values?.ductTypeId ===
                                      DuctType.Rectangular
                                        ? 0
                                        : values?.x?.toString() ?? ""
                                    }
                                    onBlur={handleInputChange}
                                    fullWidth="150px"
                                    showSkeleton={showSkeleton}
                                    decimalScale={3}
                                    thousandSeparator={true}
                                    disabled={
                                      !values?.customMatrix || disableStudy
                                    }
                                    error={errors.readingsX}
                                    mode={mode}
                                    requiredField={
                                      !Boolean(values?.x && values?.x > 0)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={1} md={disableStudy ? 1 : 0.4}>
                                  {" "}
                                  <Typography
                                    variant="subtitle2"
                                    mt={2}
                                    textAlign={"center"}
                                  >
                                    by
                                  </Typography>
                                </Grid>
                                <Grid item xs={5} md={disableStudy ? 2 : 2.5}>
                                  <FormNumeric2
                                    name={"y"}
                                    label={"Y axis ↕"}
                                    value={
                                      values?.ductTypeId ===
                                      DuctType.Rectangular
                                        ? 0
                                        : values?.y?.toString() ?? ""
                                    }
                                    onBlur={handleInputChange}
                                    showSkeleton={showSkeleton}
                                    decimalScale={3}
                                    thousandSeparator={true}
                                    error={errors.readingsY}
                                    disabled={
                                      !values?.customMatrix || disableStudy
                                    }
                                    mode={mode}
                                    requiredField={
                                      !Boolean(values?.y && values?.y > 0)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={6.4} ml={1}>
                                  <Grid container direction={"row"}>
                                    <Grid item xs={4}>
                                      {" "}
                                      <FormCheckBox
                                        name={"customMatrix"}
                                        label={"Custom Matrix"}
                                        value={values?.customMatrix}
                                        onChange={handleChangeCustomMatrix}
                                        showSkeleton={showSkeleton}
                                        disabled={disableStudy}
                                        mode={mode}
                                      ></FormCheckBox>
                                    </Grid>
                                    <Grid item xs={6} mt={1}>
                                      {showUpdateButton && (
                                        <FormButton
                                          startIcon={
                                            <DashboardCustomizeOutlined />
                                          }
                                          onClick={changeMatrix}
                                          text="Update Matrix"
                                          size="small"
                                          color="success"
                                          isSubmitting={showSkeleton}
                                        />
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Divider mt={3} />
                  <h3>Condition of Test</h3>
                  <Grid container direction={"row"} spacing={2}>
                    <Grid item xs={12} md={2.9}>
                      <FormSelect
                        name={"conditionId"}
                        label={"Conditon of Test"}
                        onChange={handleInputChange}
                        showSkeleton={showSkeleton}
                        value={values?.conditionId.toString()}
                        options={conditionOfTest}
                        disabled={disableStudy}
                        mode={mode}
                        defaultValue={{
                          key: 0,
                          value: "Select Condition of Test",
                        }}
                        requiredField={values?.conditionId === 0}
                      />
                    </Grid>
                    <Grid item xs={12} md={9} ml={2}>
                      <Grid container>
                        <Grid container>
                          <Grid item xs={12} md={4}>
                            <FormCheckSwitch
                              name={"barometricalyCompensated"}
                              label={"Correct For Barometric Compensation"}
                              showSkeleton={showSkeleton}
                              onChange={handleInputChangeBarometric}
                              disabled={disableStudy}
                              value={values?.barometricalyCompensated}
                              mode={values?.isComplete ? mode : "read&Write"}
                            ></FormCheckSwitch>
                          </Grid>
                          <Grid item xs={12} md={4.5}>
                            <FormCheckSwitch
                              name={"temperatureCompensated"}
                              label={"Correct For Temperature Compensation"}
                              showSkeleton={showSkeleton}
                              onChange={handleInputChangeCompesation}
                              disabled={disableStudy}
                              mode={values?.isComplete ? mode : "read&Write"}
                              value={values?.temperatureCompensated}
                            ></FormCheckSwitch>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <FormCheckSwitch
                              name={"totalHeat"}
                              label={"Total Heat"}
                              showSkeleton={showSkeleton}
                              onChange={handleInputChange}
                              disabled={disableStudy || params.step === "step2"}
                              mode={values?.isComplete ? mode : "read&Write"}
                              value={values?.totalHeat}
                            ></FormCheckSwitch>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {isNOC && (
                    <Grid container>
                      <Grid item xs={12} md={12}>
                        <RadioGroup aria-label="" name="" row>
                          <Grid container mt={2}>
                            <Grid item xs={3}>
                              {" "}
                              <FormControlLabel
                                checked={values?.nocVisual === true}
                                control={<Radio />}
                                label="Visual Damper Position"
                                onChange={handleCheckNoc}
                                name={"nocVisual"}
                                disabled={disableStudy}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              {" "}
                              <FormControlLabel
                                checked={values?.nocActual === true}
                                control={<Radio />}
                                label="Actual Damper Position"
                                onChange={handleCheckNoc}
                                name={"nocActual"}
                                disabled={disableStudy}
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  )}
                  {isNOC && (
                    <Grid
                      container
                      mt={3}
                      justifyContent={"center"}
                      spacing={2}
                    >
                      <Grid item xs={3} md={3}>
                        <FormNumeric2
                          name={"nocOaNumber"}
                          label={""}
                          value={values?.nocOaNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={isLoading}
                          decimalScale={3}
                          thousandSeparator={true}
                          suffix=" % O/A"
                          disabled={disableStudy}
                          mode={mode}
                        />
                      </Grid>

                      <Grid item xs={3} md={3}>
                        <FormNumeric2
                          name={"nocRaNumber"}
                          label={""}
                          value={values?.nocRaNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={isLoading}
                          decimalScale={3}
                          thousandSeparator={true}
                          suffix=" % R/A"
                          disabled={disableStudy}
                          mode={mode}
                        />
                      </Grid>

                      <Grid item xs={3} md={3}>
                        <FormNumeric2
                          name={"nocMaNumber"}
                          label={""}
                          value={values?.nocMaNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={isLoading}
                          decimalScale={3}
                          thousandSeparator={true}
                          suffix=" % M/A"
                          disabled={disableStudy}
                          mode={mode}
                        />
                      </Grid>

                      <Grid item xs={3} md={3}>
                        <FormNumeric2
                          name={"nocExhaustNumber"}
                          label={""}
                          value={values?.nocExhaustNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={isLoading}
                          decimalScale={3}
                          thousandSeparator={true}
                          suffix=" % Exhaust"
                          disabled={disableStudy}
                          mode={mode}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {isOther && (
                    <Grid container mt={5}>
                      <Grid item xs={12}>
                        <FormTextArea
                          name={"comments"}
                          label={"Comments"}
                          value={values?.comments}
                          onChange={handleInputChange}
                          mode={mode}
                        ></FormTextArea>
                      </Grid>
                    </Grid>
                  )}
                  <Divider mt={3} mb={3} />
                  {values && (
                    <>
                      <Grid container>
                        <Grid item xs={12}>
                          <h3>Readings</h3>
                        </Grid>
                      </Grid>
                      <Grid container pt={2} spacing={0.5}>
                        {values?.barometricalyCompensated && (
                          <>
                            <Grid item xs={6} md={disableStudy ? 4 : 2.8}>
                              {!values?.isActualLocalBarometricPressure && (
                                <FormNumeric2
                                  name={"localBarometricPressure"}
                                  label={`"Actual" Barometric Pressure (in Hg) Pb`}
                                  value={
                                    values?.localBarometricPressure?.toString() ??
                                    ""
                                  }
                                  onChange={handleInputChange}
                                  onBlur={saveOnBlurHandler}
                                  showSkeleton={
                                    isLoading ||
                                    isSubmittingUpdate ||
                                    disableEditData
                                  }
                                  decimalScale={3}
                                  thousandSeparator={true}
                                  mode={mode}
                                ></FormNumeric2>
                              )}
                              {values?.isActualLocalBarometricPressure && (
                                <FormNumeric2
                                  name={"useSiteSpecificDefault"}
                                  label={"Local Barometric Pressure Default"}
                                  value={
                                    values?.useSiteSpecificDefault?.toString() ??
                                    ""
                                  }
                                  onChange={handleInputChange}
                                  onBlur={saveOnBlurHandler}
                                  showSkeleton={showSkeleton}
                                  mode={"read"}
                                  decimalScale={2}
                                  thousandSeparator={true}
                                ></FormNumeric2>
                              )}
                            </Grid>
                            <Grid item xs={6} md={3}>
                              <Stack ml={2}>
                                <FormCheckSwitch
                                  name={"isActualLocalBarometricPressure"}
                                  label={"Use Site Specific Default"}
                                  showSkeleton={showSkeleton}
                                  onChange={handleChangeSpecificDefault}
                                  disabled={disableStudy || disableEditData}
                                  value={
                                    values?.isActualLocalBarometricPressure
                                  }
                                ></FormCheckSwitch>
                              </Stack>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12} pb={3} mt={3}>
                        {values && id && (
                          <VelgridGrid
                            id={id}
                            x={values?.x}
                            y={values?.y}
                            saveItems={saveItems}
                            disabled={
                              disableStep1 ||
                              disableEditData ||
                              changeMatrixButton
                            }
                            showSkeleton={isLoading || isSubmittingUpdate}
                            onBlur={calcTotal}
                            systemOfMeasurement={systemOfMeasurement}
                            values={values}
                            dataX={dataX}
                            setDataX={setDataX}
                            setDataY={setDataY}
                            dataY={dataY}
                            stepContinuos={stepContinuos}
                            showSteady={showSteady}
                            setShowSteady={setShowSteady}
                            setValues={setValues}
                            setChangeMatrixButton={setChangeMatrixButton}
                            changeMatrixButton={changeMatrixButton}
                            setButtonAction={setButtonAction}
                            buttonAction={buttonAction}
                            dimensions={dimensions}
                            setDimensions={setDimensions}
                            executeRefresh={executeRefresh}
                          />
                        )}
                      </Grid>
                      {values?.totalHeat && messagePsychrometric.text !== "" && (
                        <Grid item xs={12} justifyContent={"center"}>
                          <Stack mb={2}>
                            <Alert severity={messagePsychrometric.color}>
                              {messagePsychrometric.text}
                            </Alert>
                          </Stack>
                        </Grid>
                      )}
                      <Grid container spacing={3}>
                        <Grid item md={3} xs={6}>
                          <FormNumeric2
                            name={"totalVelocity"}
                            label={"Total Velocity"}
                            value={values?.totalVelocity?.toString() ?? ""}
                            mode="read"
                            showSkeleton={showSkeleton}
                            onChange={handleInputChange}
                            decimalScale={2}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item md={3} xs={6}>
                          <FormNumeric2
                            name={"avgVelocity"}
                            label={"Avg Velocity"}
                            value={values?.avgVelocity?.toFixed(3) ?? ""}
                            mode="read"
                            showSkeleton={showSkeleton}
                            onChange={handleInputChange}
                            decimalScale={2}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item md={3} xs={6}>
                          <FormNumeric2
                            name={"trueCorectedAirVelocity"}
                            label={"True Corrected Average Velocity"}
                            value={
                              values?.trueCorectedAirVelocity?.toFixed(3) ?? ""
                            }
                            mode="read"
                            showSkeleton={showSkeleton}
                            onChange={handleInputChange}
                            decimalScale={2}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item md={3} xs={6}>
                          <FormNumeric2
                            name={"totalCFM"}
                            label={`Actual ${systemOfMeasurement.get("acfm")}`}
                            value={values?.totalCFM?.toFixed(3) ?? ""}
                            mode="read"
                            showSkeleton={showSkeleton}
                            onChange={handleInputChange}
                            decimalScale={2}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item md={3} xs={6}>
                          <Grid container>
                            <Grid item xs={10}>
                              <FormNumeric2
                                name={"required"}
                                label={`Required ${systemOfMeasurement.get(
                                  "cfm"
                                )}`}
                                value={values?.required?.toString() ?? ""}
                                onChange={handleInputChange}
                                disabled={
                                  disableStep1 ||
                                  disableStudy ||
                                  disableEditData
                                }
                                onBlur={saveOnBlurHandler}
                                showSkeleton={showSkeleton}
                                decimalScale={3}
                                thousandSeparator={true}
                                mode={mode}
                              ></FormNumeric2>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={3} xs={6}>
                          <Grid container>
                            <Grid item xs={10}>
                              <FormNumeric2
                                name={"akFactor"}
                                label={"AK Factor"}
                                value={values?.akFactor?.toString() ?? ""}
                                onChange={handleInputChange}
                                onBlur={saveOnBlurHandler}
                                disabled={
                                  disableStep1 ||
                                  disableStudy ||
                                  disableEditData
                                }
                                showSkeleton={showSkeleton}
                                decimalScale={2}
                                thousandSeparator={true}
                                mode={mode}
                              ></FormNumeric2>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={3} xs={6}>
                          <FormNumeric2
                            name={"percentajeOfDesign"}
                            label={"% of Design"}
                            value={values?.percentajeOfDesign?.toString() ?? ""}
                            mode="read"
                            showSkeleton={showSkeleton}
                            color={
                              values &&
                              ((values?.percentajeOfDesign ?? 0) >
                                (values?.requiredCFMSystemToleranceTop
                                  ? values?.requiredCFMSystemToleranceTop
                                  : 0) +
                                  100 ||
                                (values?.percentajeOfDesign ?? 0) <
                                  100 -
                                    (values?.requiredCFMSystemToleranceBottom
                                      ? values?.requiredCFMSystemToleranceBottom
                                      : 0))
                                ? "red"
                                : undefined
                            }
                            onChange={handleInputChange}
                            decimalScale={2}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item md={3} xs={6}>
                          <Grid container>
                            <Grid item xs={10}>
                              <FormNumeric2
                                name={"dryBulbF"}
                                label={
                                  "Airflow Temperature For Energy Calculation"
                                }
                                value={values?.dryBulbF?.toString() ?? ""}
                                onChange={handleInputChange}
                                onBlur={onBlurCalcPsycometrics}
                                disabled={
                                  disableStep1 ||
                                  disableStudy ||
                                  disableEditData
                                }
                                showSkeleton={showSkeleton}
                                error={errors.dryBulbF}
                                decimalScale={2}
                                thousandSeparator={true}
                                suffix={disableStudy ? " DB" : ""}
                                mode={mode}
                              ></FormNumeric2>
                            </Grid>
                            <Grid item xs={2}>
                              {!disableStudy && (
                                <Typography pl={2} pt={1}>
                                  DB
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        {values.totalHeat && (
                          <>
                            <Grid item md={3} xs={6}>
                              <Grid container>
                                <Grid item xs={10}>
                                  {values?.relativeHumidityBool === true && (
                                    <FormNumeric2
                                      name={"relativeHumidity"}
                                      label={"Relative Humidity"}
                                      value={
                                        values?.relativeHumidity?.toString() ??
                                        ""
                                      }
                                      onChange={handleInputChange}
                                      onBlur={onBlurCalcPsycometrics}
                                      disabled={
                                        disableStep1 ||
                                        disableStudy ||
                                        disableEditData
                                      }
                                      showSkeleton={showSkeleton}
                                      decimalScale={3}
                                      thousandSeparator={true}
                                    ></FormNumeric2>
                                  )}
                                  {values?.wetBulbFBool === true && (
                                    <FormNumeric2
                                      name={"wetBulbF"}
                                      label={"Wet bulb F"}
                                      value={values?.wetBulbF?.toString() ?? ""}
                                      onChange={handleInputChange}
                                      onBlur={onBlurCalcPsycometrics}
                                      disabled={
                                        disableStep1 ||
                                        disableStudy ||
                                        disableEditData
                                      }
                                      showSkeleton={showSkeleton}
                                      decimalScale={3}
                                      thousandSeparator={true}
                                    ></FormNumeric2>
                                  )}
                                  {values?.dewPointBool === true && (
                                    <FormNumeric2
                                      name={"dewPoint"}
                                      label={"Dew Point"}
                                      value={values?.dewPoint?.toString() ?? ""}
                                      onChange={handleInputChange}
                                      onBlur={onBlurCalcPsycometrics}
                                      disabled={
                                        disableStep1 ||
                                        disableStudy ||
                                        disableEditData
                                      }
                                      showSkeleton={showSkeleton}
                                      decimalScale={3}
                                      thousandSeparator={true}
                                    ></FormNumeric2>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <RadioGroup aria-label="" name="" row>
                                <FormControlLabel
                                  checked={
                                    values?.relativeHumidityBool === true
                                  }
                                  control={<Radio />}
                                  label="Relative Humidity"
                                  onChange={handleChangeRadio}
                                  name={"relativeHumidityBool"}
                                  disabled={
                                    disableStep1 ||
                                    disableStudy ||
                                    disableEditData
                                  }
                                />
                                <FormControlLabel
                                  checked={values?.wetBulbFBool === true}
                                  control={<Radio />}
                                  label="Wet bulb F"
                                  onChange={handleChangeRadio}
                                  name={"wetBulbFBool"}
                                  disabled={
                                    disableStep1 ||
                                    disableStudy ||
                                    disableEditData
                                  }
                                />
                                <FormControlLabel
                                  checked={values?.dewPointBool === true}
                                  control={<Radio />}
                                  label="Dew Point"
                                  onChange={handleChangeRadio}
                                  name={"dewPointBool"}
                                  disabled={
                                    disableStep1 ||
                                    disableStudy ||
                                    disableEditData
                                  }
                                />
                              </RadioGroup>
                            </Grid>
                          </>
                        )}

                        <Grid item xs={3}>
                          <FormNumeric2
                            name={"btuh"}
                            label={`Total ${systemOfMeasurement.get("btuh")}`}
                            value={values?.btuh?.toString() ?? ""}
                            onChange={handleInputChange}
                            mode="read"
                            showSkeleton={showSkeleton}
                            decimalScale={2}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item md={3} xs={6}>
                          <FormNumeric2
                            name={"sensibleHeat"}
                            label={`Sensible ${systemOfMeasurement.get(
                              "btuh"
                            )}`}
                            value={values?.sensibleHeat?.toString() ?? ""}
                            mode="read"
                            showSkeleton={showSkeleton}
                            onChange={handleInputChange}
                            decimalScale={2}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FooterButtons
                      activeStep={1}
                      stepsCount={3}
                      projectId={values?.projectId}
                      companyId={user?.companyId as number}
                      isSaving={showSkeleton}
                      reportName="Velgrid Matrix Test Report"
                      saveHandler={handleSave}
                      nextStepHandler={nextStepHandler}
                      disabled={disableStudy}
                      previousStepHandler={handleBackStep}
                      isComplete={values?.isComplete ?? true}
                      singleStep={true}
                      hideSaveButton={disableStudy}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {project && projectReport && velgridMatrix && (
                      <CompleteStudyPopup
                        isDialogOpen={isDialogOpen}
                        setIsDialogOpen={setIsDialogOpen}
                        project={project}
                        projectReport={projectReport}
                        completed={velgridMatrix?.isComplete}
                        infectiousComplianceControl={
                          infectiousComplianceControl
                        }
                        parentStudy={parentStudy ?? null}
                        singleStep={disableCompleteStudy}
                        handlerCustomAccept={refreshCompletedStudy}
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {showSteadyPopUp && (
        <SteadyStateDialog
          reportId={values?.id}
          reportTypeId={6}
          projectId={values?.projectId}
          reportCompleted={values?.isComplete}
          setDisableEditData={setDisableEditData}
        />
      )}
    </>
  );
};

export default VelgridReport;
